<template>
  <section class="m-order-detail m-scroll" @scroll="handleScroll($event)">
    <!-- 头部导航 -->
    <div class="header">
      <img @click="handleBack" src="@/assets/merchant/icon-fanhui@2x.png" alt="back">
      <p>订单详情</p>
      <span></span>
    </div>
    <div class="header-bg" :style="`opacity:${(200-this.offsetTop) / 200};`"></div>

    <!-- 订单状态 -->
    <div class="order-status">
      <p>{{orderDetail.order_status_title}}</p>
      <p v-if="[0, 1].indexOf(orderDetail.order_status) == -1 || !orderDetail.batch_info">{{orderDetail.delivery_method_title}}</p>
      <p v-else>该商品预计{{(orderDetail.batch_info.cn_day - 0) + (orderDetail.batch_info.d_day - 0) + (orderDetail.batch_info.f_day - 0)}}个工作日到仓</p>
    </div>

    <!-- 物流信息 -->
    <div class="logistics">
      <div class="number van-hairline--bottom">
        <p v-if="orderDetail.delivery_method != 1">物流单号：{{orderDetail.order_logistics.carrier_number || '-'}}</p>
        <p v-else>到指定地点自提</p>
        <span v-if="orderDetail.delivery_method != 1" @click="copyCarrierNumber" class="copy-button"
        :data-clipboard-text="(orderDetail.order_logistics.carrier_number || '暂无物流单号')">复制</span>
      </div>
      <div class="number van-hairline--bottom" v-if="orderDetail.delivery_method != 1">
        <p>物流公司：{{orderDetail.order_logistics.carrier_name || '-'}}</p>
      </div>
      <div class="address">
        <p v-if="orderDetail.delivery_method != 1">收货信息：</p>
        <p v-else>自提地址：</p>
        <div v-if="orderDetail.delivery_method != 1">
          <span>{{  orderDetail.order_logistics.name + ' ' +orderDetail.order_logistics.surname }}</span>
          <span>{{orderDetail.order_logistics.tel}}</span>
        </div>
        <p class="van-multi-ellipsis--l2" v-if="orderDetail.delivery_method != 1">
          {{ orderDetail.order_logistics.address + ' ' + (orderDetail.order_logistics.city_arr ? orderDetail.order_logistics.city_arr.ename : orderDetail.order_logistics.city_name) + ' ' + orderDetail.order_logistics.province_arr.ename + ' '
          + orderDetail.order_logistics.country_arr.ename  + ' ' +  orderDetail.order_logistics.zipcode}}
        </p>
        <p class="van-multi-ellipsis--l2" v-else>11121 Horseshoe Way Unit 133, Richmond, BC V7A 5G7</p>
      </div>
    </div>

    <!-- 商品信息 -->
    <ul class="goods-list m-padding">
      <li class="goods-title">
        <router-link to>商品信息</router-link>
        <div  v-if="false" class="view_poster" @click="viewPoster">查看活动海报</div>
      </li>
      <div class="gift-richmond"  v-if="orderDetail.order_gift && orderDetail.order_status != 4 && orderDetail.close_status != 2">
          <img class="dp" src="@/assets/lottery/dp-min.png" alt="" />
          <div class="flex">
            <div class="gift-goods">
                <img :src="orderDetail.order_gift.pic" alt="">
            </div>
            <div class="gift-info">
                <p>{{orderDetail.order_gift.title}}</p>
            </div>
            <div class="gift-rule">
                <span>x</span>
                <span>1</span>
            </div>
          </div>
      </div>
      <li class="goods-item" v-for="(item, index) in orderDetail.order_goods" :key="index">
        <router-link :to="{path:`/zh/goods/detail/${item.goods_id}`,query: {pre: item.is_presale == 1 ? 1 : 0}}">
          <img :src="item.goods_sku_pic_url" alt="goods">
          <div>
            <p class="van-ellipsis">{{item.goods_name}}</p>
            <p><span>{{item.sku_name}}</span><span>数量：{{item.goods_number}}</span></p>
            <p>{{orderDetail.order_logistics.country == 507 ? 'US$':'C$'}}{{item.goods_price}}</p>
          </div>
        </router-link>
        
      </li>
      <li class="goods-btns">
        <span class="btn" @click.prevent="addCart(orderDetail)" v-if="orderDetail.order_status == 0 && orderDetail.order_goods[0].goods_id != '3751'">再买一单</span>
        <span class="btn-active" @click.prevent="onPay" v-if="orderDetail.order_status == 0">继续支付</span>
        <router-link :to="`/zh/aftersale/select/${orderDetail.id}`" class="btn-acitve"  v-if="orderDetail.order_status == 2 || orderDetail.order_status == 1">申请售后</router-link>
        <span class="btn-active" v-if="orderDetail.order_status == 3" @click="onComment(orderDetail.id)">评价订单</span>
        <!-- <span class="btn-active" v-if="orderDetail.order_status == 4">再次购买</span> -->
      </li>
    </ul>


    <!-- 订单信息 -->
    <div class="order-info m-padding">

      <div class="info-cell">
        <span>订单编号：</span>
        <p>{{orderDetail.title}}</p>
      </div>
      <div class="info-cell ">
        <span>下单时间：</span>
        <p>{{orderDetail.created_at}}</p>
      </div>
      <div class="info-cell-line"></div>

      <div class="info-cell">
        <span>支付方式：</span>
        <p>{{orderDetail.pay_method_title}}</p>
      </div>
      <div class="info-cell ">
        <span>支付时间：</span>
        <p>{{orderDetail.pay_time_title}}</p>
      </div>
      <div class="info-cell-line"></div>
      <div class="info-cell ">
        <span>配送方式：</span>
        <p>{{orderDetail.delivery_method_title}}</p>
      </div>
      <div class="info-cell-line"></div>

      <div class="price-cell">
        <span>商品金额</span>
        <span>{{orderDetail.order_logistics.country == 507 ? 'US$':'C$'}}{{orderDetail.goods_amount}}</span>
      </div>
      <div class="price-cell" v-if="orderDetail.pst_amount != '0.00'">
        <span>PST({{((orderDetail.pst_amount/(orderDetail.goods_amount - orderDetail.goods_discount_fee))*100).toFixed(0)}}%)</span>
        <span>{{orderDetail.order_logistics.country == 507 ? 'US$':'C$'}}{{orderDetail.pst_amount}}</span>
      </div>
      <div class="price-cell" v-if="orderDetail.gst_amount != '0.00'">
        <span>GST({{((orderDetail.gst_amount/(orderDetail.goods_amount - orderDetail.goods_discount_fee))*100).toFixed(0)}}%)</span>
        <span>{{orderDetail.order_logistics.country == 507 ? 'US$':'C$'}}{{orderDetail.gst_amount}}</span>
      </div>
      <div class="price-cell" v-if="orderDetail.hst_amount != '0.00'">
        <span>HST({{((orderDetail.hst_amount/(orderDetail.goods_amount - orderDetail.goods_discount_fee))*100).toFixed(0)}}%)</span>
        <span>{{orderDetail.order_logistics.country == 507 ? 'US$':'C$'}}{{orderDetail.hst_amount}}</span>
      </div>
      <div class="price-cell" v-if="orderDetail.qst_amount != '0.00'">
        <span>QST({{((orderDetail.qst_amount/(orderDetail.goods_amount - orderDetail.goods_discount_fee))*100).toFixed(0)}}%)</span>
        <span>{{orderDetail.order_logistics.country == 507 ? 'US$':'C$'}}{{orderDetail.qst_amount}}</span>
      </div>
      <div class="price-cell" v-if="orderDetail.order_logistics.country == 507">
        <span>税费</span>
        <span>US$0.00(包税)</span>
      </div>
      <div class="price-cell">
        <span>运费</span>
        <span>{{orderDetail.order_logistics.country == 507 ? 'US$':'C$'}}{{orderDetail.shipping_fee}}</span>
      </div>
      <div class="price-cell" v-if="orderDetail.goods_discount_fee != '0.00'">
        <span>优惠</span>
        <span class="active">-{{orderDetail.order_logistics.country == 507 ? 'US$':'C$'}}{{orderDetail.goods_discount_fee}}</span>
      </div>
      <div class="price-cell" v-if="orderDetail.goods_wallet_fee && orderDetail.goods_wallet_fee != '0.00'">
        <span>抵扣</span>
        <span class="active">-{{orderDetail.order_logistics.country == 507 ? 'US$':'C$'}}{{orderDetail.goods_wallet_fee}}</span>
      </div>
      <div class="price-total">
        <span>实付款：</span>
        <span class="active">{{orderDetail.order_logistics.country == 507 ? 'US$':'C$'}}{{orderDetail.order_total_amount}}</span>
      </div>
    </div>

    <!-- 为您推荐 -->
    <m-divider title="为您推荐" class="recomnd"></m-divider>
    <goods-columns @cartClick="handleCart" :data="recomnd"></goods-columns>


    <!-- 选择SKU 加入购物车 -->
    <m-sku title="加入购物车" :show="skuModal" @close="val=>skuModal=val" @success="handleCartSuccess" :goodsId="goodsId"></m-sku>
    
    <!-- 加入购物车动画 -->
    <m-animate :start="start" :dom="start_dom" @end="handleCartEnd"></m-animate>

    <!-- 购物车浮窗 -->
    <transition name="van-fade">
        <CartFlex v-if="start" />
    </transition>
    <div class="friday" v-if="posterShow" @click="posterShow = false">
      <div class="main">
          <div class="poster">
              <img class="title_p" src="@/assets/eleven/title_zh.png" alt="">
              <img class="share-img" :src="imgSrc" alt="">
          </div>
      </div>
    </div>
  </section>
</template>

<script>
import MDivider     from '@/components/zh/m-divider.vue'
import GoodsColumns from '@/components/zh/goods-columns.vue'
import MSku         from '@/components/zh/m-sku.vue'
import MAnimate     from '@/components/zh/cart-animate.vue'
import CartFlex     from '@/components/zh/cart-flex.vue'
import { getOrderDetail,addCart } from '@/api/zh/order.js'
import { getOrderRemGoodsList } from '@/api/en/order.js'
import { poster_get } from '@/api/zh/index.js'


import Clipboard from 'clipboard'
// import { parseTime } from '@/untils/js/common.js'
export default {
  name:'Index',
  components:{ MDivider, GoodsColumns, MSku, MAnimate, CartFlex },
  data(){
    return {
      offsetTop:0,
      data:8,
      skuModal:false,
      start:false,
      start_dom:null,
      orderDetail: {order_logistics:{city_arr:{}, country_arr: {}, province_arr:{},carrier_number:'',pay_title:''}},
      goodsId: '',
      recomnd: [],
      loading: false,
      islock: false,
      page: 1,
      posterShow: false,
      imgSrc: ''
    }
  },

  created(){
    this.getOrderDetailHandle()
    this.getRecommnd()
  },
  methods:{
    // 查看海报
    viewPoster() {
      poster_get({pay_title: this.orderDetail.pay_title}).then(res => {  
          if(res){
              this.imgSrc = res.data.pic?.url;
              this.posterShow = true;
          }else{
            this.$notify({ type: 'warning', message: '该订单未查询到活动海报！' })
          }
      })
    },
     // 内容滑动事件
    handleScroll(e){
      this.offsetTop = e.target.scrollTop 
      if(e.target.scrollHeight - e.target.clientHeight- e.target.scrollTop <= 50){
        if(!this.islock){
          e.preventDefault()
          e.stopPropagation()
          this.loading = true
          this.page++
          this.getRecommnd()
        }
        this.islock = true
      }
    },
    // 返回上一页
    handleBack(){
      this.$router.go(-1)
    },
    // 商品列表购物车点击事件
    handleCart(goods,e){
      this.goodsId = goods
      this.skuModal  = true
      this.start_dom = e 
    },
     // sku 弹窗加入购物车成功回调
    handleCartSuccess(){
      this.skuModal = false
      let timer = setTimeout( () => {
        this.start  = true
        clearTimeout(timer)
      },300)
    },
    // 加入购物车动画结束
    handleCartEnd(){
      this.start    = false
    },
    getOrderDetailHandle() {
      // 获取订单详情
      getOrderDetail({id: this.$route.params.id}).then(res => {
        if(res) {
          if(res.data.batch_info) {
          //   res.data.batch_info.time.sta_time = parseTime(res.data.batch_info.time.sta_time, '{y}-{m}-{d}')
          //   res.data.batch_info.time.end_time = parseTime(res.data.batch_info.time.end_time, '{y}-{m}-{d}')
          }
          this.orderDetail = res.data
        }
      })
    },
    getRecommnd() {
      // 获取自定义为你推荐
      this.islock = true
      this.loading  = true
      getOrderRemGoodsList({page: this.page, goods_id: this.$route.params.id}).then(res => {
        this.loading  = false
        if(res) {
          this.recomnd = this.recomnd.concat(res.data.data)
          this.islock   = false
        }
      })
    },
    copyCarrierNumber() {
      var clipboard = new Clipboard('.copy-button')
      clipboard.on('success', () => {
        this.$notify({ type: 'success', message: '复制成功' })
      })
      clipboard.on('error', () =>{
        // 不支持复制
        this.$notify({ type: 'warning', message: '该浏览器不支持复制'})
      })
    },
    // 加入购物车
    addCart(item){
      let params = {
        order_id:item.id
      }
      addCart(params).then(res =>{
        if(res) {
          this.$router.push('/zh/cart')
        }
      })
    },
    onPay() {
      // 继续支付
      localStorage.setItem('order', JSON.stringify(this.orderDetail))
      if(this.orderDetail.order_logistics && this.orderDetail.order_logistics.country == 507) {
        this.$router.replace({path: '/zh/usPay', query: {id: this.orderDetail.id}})
      } else {
        this.$router.replace({path: '/zh/pay', query: {id: this.orderDetail.id}})
      }
      if(this.orderDetail.order_goods[0].goods_id == '3751') {
        localStorage.setItem('bargain', 1)
      }
    },
    onComment(id) {
      this.$router.push({path: '/zh/comment/submit', query: {id: id}})
    }
  }
}
</script>

<style lang="less" scoped>
@import './detail.less';
.gift-richmond {
        width: 100%;
        background-image: url("../../../assets/lottery/Group970.png");
        height: 110px;
        background-size: 100% 100%;
        margin-bottom: 10px;
        position: relative;
        .dp {
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0.3;
            animation: shan 0.5s infinite;
          }
          @keyframes shan {
            from {
              opacity: 0.3;
            }
            to {
              opacity: 1;
            }
          }
          .flex {
            width: 100%;
            height: 100%;
            padding: 18px 20px 18px 10px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
          .gift-goods {
              width: 100px;
              height: 70px;
              background-image: url("../../../assets/lottery/Group971.png");
              background-size: 100% 100%;
              img {
                  display: block;
                  height: 80%;
                  margin: auto;
              }
          }
          .gift-info {
              width: 175px;
              p {
                  &:nth-child(1) {
                      font-size: 15px;
                      line-height: 25px;
                      color: #000;
                  }
                  &:nth-child(2),&:nth-child(3) {
                      font-size: 12px;
                      color: #666666;
                      line-height: 20px;
                  }
              }
          }
          .gift-rule {
                  width: 30px;
                  text-align: center;
                  display: flex;
                  justify-content: center;
                  span {
                    display: block;
                      &:nth-child(1) {
                          font-size: 12px;
                      }
                      &:nth-child(1) {
                          font-size: 16px;
                      }
                  }
          }
      }
}
</style>